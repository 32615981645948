import { Grid } from "@mui/material";
import { BASE_URL } from "../utils/urls_utils";

function TitleAndImage() {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
    >
      <Grid item justifyItems={"center"}>
        <Grid sx={{ width: "100%", mx: "auto" }}>
          <img
            src={`${BASE_URL}/me.png`}
            alt="Me"
            style={{
              borderRadius: "15%",
              width: "100%",
            }}
          />
        </Grid>
      </Grid>
      <Grid item>
        <div className="title-wrapper">
          <h1 className="sweet-title">
            <span data-text="Elias">Elias</span>
            <span data-text="Owis">Owis</span>
          </h1>
        </div>
        <div className="grid-center">
          <h1 className="retro-text">
            <span>Software Engineer</span>
            <span>Software Engineer</span>
          </h1>
        </div>
        <div className="grid-center">
          <img
            src={`${BASE_URL}/title.png`}
            alt="Title"
            style={{
              width: "75%",
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default TitleAndImage;

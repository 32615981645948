import {
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Tabs from "@mui/base/Tabs";
import {
  StyledTab,
  StyledTabPanel,
  StyledTabsList,
} from "../styles/styled_tabs";
import ExtensionIcon from "@mui/icons-material/Extension";
import { BASE_URL } from "../utils/urls_utils";
import ImageGallery from "react-image-gallery";

function TopicsDetectorProject() {
  const thumbnailHeight = 100;
  const thumbnailWidth = 100;

  const images = [
    {
      original: `${BASE_URL}/projects_images/topics_detector/topics_detector.jpg`,
      thumbnail: `${BASE_URL}/projects_images/topics_detector/topics_detector.jpg`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
    {
      original: `${BASE_URL}/projects_images/topics_detector/topics_detector_result.jpg`,
      thumbnail: `${BASE_URL}/projects_images/topics_detector/topics_detector_result.jpg`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
  ];

  return (
    <Tabs defaultValue={0}>
      <StyledTabsList>
        <StyledTab value={0}>Gallery</StyledTab>
        <StyledTab value={1}>Tech Stack</StyledTab>
      </StyledTabsList>
      <StyledTabPanel value={0}>
        <ImageGallery
          items={images}
          autoPlay
          showFullscreenButton={false}
          showThumbnails={false}
          //   showBullets
          //   thumbnailPosition="left"
        />
      </StyledTabPanel>
      <StyledTabPanel value={1}>
        <Grid sx={{ color: "#ffffff", px: 2, pb: 3 }}>
          <Typography variant="h4">Overview:</Typography>
          <Divider />
          <Typography>
            This is a React project that analyzes articles and discovers topics
            which articles talk about. I built 2 version of this project:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>
                First version is based on ChatGPT and prompt engineering, where
                I send request to OpenAI API to detect topics using ChatGPT.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>
                Second version is based on TextRazor API, you can test it on: (
                <Link href="https://engelias.website/topics_detector/">
                  Topic Detector
                </Link>
                )
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
      </StyledTabPanel>
    </Tabs>
  );
}

export default TopicsDetectorProject;

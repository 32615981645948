import { Divider, Grid, Link, Typography } from "@mui/material";
import Tabs from "@mui/base/Tabs";
import {
  StyledTab,
  StyledTabPanel,
  StyledTabsList,
} from "../styles/styled_tabs";
import { BASE_URL } from "../utils/urls_utils";
import ImageGallery from "react-image-gallery";

function BytCryptexProject() {
  const thumbnailHeight = 100;
  const thumbnailWidth = 100;

  const images = [
    {
      original: `${BASE_URL}/projects_images/bytcryptex/home_page.jpg`,
      thumbnail: `${BASE_URL}/projects_images/bytcryptex/home_page.jpg`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
    {
      original: `${BASE_URL}/projects_images/bytcryptex/customer_bot1.jpg`,
      thumbnail: `${BASE_URL}/projects_images/bytcryptex/customer_bot1.jpg`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
    {
      original: `${BASE_URL}/projects_images/bytcryptex/customer_bot2.jpg`,
      thumbnail: `${BASE_URL}/projects_images/bytcryptex/customer_bot2.jpg`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
    {
      original: `${BASE_URL}/projects_images/bytcryptex/customer_bot3.jpg`,
      thumbnail: `${BASE_URL}/projects_images/bytcryptex/customer_bot3.jpg`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
    {
      original: `${BASE_URL}/projects_images/bytcryptex/admin_bot.jpg`,
      thumbnail: `${BASE_URL}/projects_images/bytcryptex/admin_bot.jpg`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
    {
      original: `${BASE_URL}/projects_images/bytcryptex/currencies_table.jpg`,
      thumbnail: `${BASE_URL}/projects_images/bytcryptex/currencies_table.jpg`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
    {
      original: `${BASE_URL}/projects_images/bytcryptex/currencies_exchange_options_table.jpg`,
      thumbnail: `${BASE_URL}/projects_images/bytcryptex/currencies_exchange_options_table.jpg`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
    {
      original: `${BASE_URL}/projects_images/bytcryptex/exchange_rate_options_table.jpg`,
      thumbnail: `${BASE_URL}/projects_images/bytcryptex/exchange_rate_options_table.jpg`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
    {
      original: `${BASE_URL}/projects_images/bytcryptex/contact_orders_table.png`,
      thumbnail: `${BASE_URL}/projects_images/bytcryptex/contact_orders_table.png`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
    {
      original: `${BASE_URL}/projects_images/bytcryptex/customer_detail.png`,
      thumbnail: `${BASE_URL}/projects_images/bytcryptex/customer_detail.png`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
    {
      original: `${BASE_URL}/projects_images/bytcryptex/exchange_orders_table.jpg`,
      thumbnail: `${BASE_URL}/projects_images/bytcryptex/exchange_orders_table.jpg`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
    {
      original: `${BASE_URL}/projects_images/bytcryptex/exchange_order_detail.jpg`,
      thumbnail: `${BASE_URL}/projects_images/bytcryptex/exchange_order_detail.jpg`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
  ];

  return (
    <Tabs defaultValue={0}>
      <StyledTabsList>
        <StyledTab value={0}>Gallery</StyledTab>
        <StyledTab value={1}>Overview</StyledTab>
      </StyledTabsList>
      <StyledTabPanel value={0}>
        <ImageGallery
          items={images}
          autoPlay
          showFullscreenButton={false}
          showThumbnails={false}
          //   showBullets
          //   thumbnailPosition="left"
        />
      </StyledTabPanel>
      <StyledTabPanel value={1}>
        <Grid sx={{ color: "#ffffff", px: 2, pb: 3 }}>
          <Typography variant="h4">Overview:</Typography>
          <Divider />
          <Typography>
            Engineered a Currency Exchange Telegram Bot with an integrated Admin
            Panel, utilizing Django and a finite state machine for tailored
            customer interactions. The system facilitated seamless currency
            exchange request collection via Telegram, streamlining request
            processing in the admin panel. Notifications with response options
            were dispatched to an admin bot, enhancing operational efficiency
            and customer engagement. To encourage user adoption, a referral
            system was integrated. The integration between customer bot, admin
            bot and admin panel led to highly efficient request handling for the
            currency exchange company based in the UAE.
          </Typography>
          <Typography>
            <Link href="https://t.me/Exchange_usdt_aed_bot" target="_blank">
              BytCryptex Bot Link
            </Link>
          </Typography>
        </Grid>
      </StyledTabPanel>
    </Tabs>
  );
}

export default BytCryptexProject;

import { Copyright, Email, GitHub, LinkedIn, Phone } from "@mui/icons-material";
import {
  Alert,
  Grid,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import CopyUtils from "../utils/copy_utils";
import MediumIcon from "../icons/Medium";

function ContactMe() {
  const isMobile = useMediaQuery("@media (max-width: 1000px)");

  const contactInfo = {
    email: "elias@engelias.website",
    phone: "+971524065289",
  };

  const [copyState, setCopyState] = useState({
    open: false,
    status: "success",
    text: "Copied",
  });
  const { open: copyOpen, status: copyStatus, text: copyAlertText } = copyState;

  const showCopySuccessAlertMessage = () => {
    setCopyState({
      open: true,
      status: "success",
      text: "Copied!",
    });
  };

  const showCopyWarningAlertMessage = () => {
    setCopyState({
      open: true,
      status: "warning",
      text: "Couldn't copy!",
    });
  };

  const copyTextToClipboard = (text) => {
    CopyUtils.copyTextToClipboard({
      text: text,
      onSuccess: showCopySuccessAlertMessage,
      onError: showCopyWarningAlertMessage,
    });
  };

  const closeCopyAlertMessage = () => {
    setCopyState({
      open: false,
    });
  };

  return (
    <>
      <Grid sx={{ my: 5 }}>
        <Typography
          h1
          className="neon-text animated-box"
          neontext=""
          sx={{
            textAlign: "center",
            fontSize: "2.5rem",
            width: "75%",
            mx: "auto",
          }}
        >
          Contact Me
        </Typography>
      </Grid>
      <div className="contact-container">
        <ul className="contact">
          <li onClick={() => copyTextToClipboard(contactInfo.email)}>
            <div className="icon">
              <Email fontSize="large" />
            </div>
            <div className="descr">elias @engelias.website</div>
          </li>
          <li onClick={() => copyTextToClipboard(contactInfo.phone)}>
            <div className="icon">
              <Phone fontSize="large" />
            </div>
            <div className="descr">+971 524065289</div>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/elias-awess/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="icon">
                <LinkedIn fontSize="large" />
              </div>
              <div className="title">Elias Owis</div>
            </a>
          </li>
          <li>
            <a
              href="https://github.com/Eng-Elias"
              target="_blank"
              rel="noreferrer"
            >
              <div className="icon">
                <GitHub fontSize="large" />
              </div>
              <div className="title">EngElias</div>
            </a>
          </li>
          <li>
            <a
              href="https://medium.com/@eng_elias"
              target="_blank"
              rel="noreferrer"
            >
              <div className="icon my-4">
                <MediumIcon />
              </div>
              <div className="title">EngElias</div>
            </a>
          </li>
        </ul>
      </div>
      <div
        style={{
          position: !isMobile ? "absolute" : "",
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          backgroundColor: "rgb(40,40,40,0.5)",
          color: "white",
          width: "100%",
        }}
      >
        <Copyright sx={{ mr: 1 }} /> Eng. Elias Owis
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={copyOpen}
        onClose={closeCopyAlertMessage}
        autoHideDuration={2000}
        key="bottom-center"
      >
        <Alert severity={copyStatus} sx={{ width: "100%" }}>
          {copyAlertText}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ContactMe;

import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  FadeIn,
  Move,
  MoveOut,
  Sticky,
  StickyIn,
  ZoomIn,
} from "react-scroll-motion";
import TitleAndImage from "../sections/TitleAndImage";
import WhoAmI from "../sections/WhoAmI";
import MyJourney from "../sections/MyJourney";
import Skills from "../sections/Skills";
import {
  ExperienceScrollPage1,
  ExperienceScrollPage2,
  ExperienceScrollPage3,
  ExperienceScrollPage4,
} from "../sections/Experience";
import FreelanceAccounts from "../sections/FreelanceAccounts";
import DownloadCV from "../sections/DownloadCV";
import ContactMe from "../sections/ContactMe";
import {
  // MoreProjectsAndDetailsOnNotion,
  MyProjects1,
  MyProjects2,
  MyProjects3,
  MyProjects4,
  MyProjects5,
} from "../sections/MyProjects";
import { navItemsIds } from "../components/NavBar";
import {
  AdventureCard,
  FreestyleAdventures,
} from "../sections/FreestyleAdventures";
import DigitalStore from "../sections/DigitalStore";
import { Grid } from "@mui/material";
import { adventures } from "../data/adventures";

function HomePage() {
  const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
  const FadeUp = batch(Fade(), Move(), Sticky());

  return (
    <ScrollContainer>
      <ScrollPage>
        <Animator
          animation={batch(Fade(), Sticky(), MoveOut(0, -200))}
          style={{ width: "100%" }}
        >
          <TitleAndImage />
        </Animator>
      </ScrollPage>
      <ScrollPage className={navItemsIds.whoAmI}>
        <Animator animation={ZoomInScrollOut} style={{ width: "100%" }}>
          <WhoAmI />
        </Animator>
      </ScrollPage>
      <ScrollPage></ScrollPage>
      <ScrollPage>
        <Animator animation={FadeUp} style={{ width: "100%" }}>
          <MyJourney />
        </Animator>
      </ScrollPage>
      <ScrollPage className={navItemsIds.skills}>
        <Animator animation={batch(Fade(), Sticky())} style={{ width: "75%" }}>
          <Skills />
        </Animator>
      </ScrollPage>
      {/* <ScrollPage></ScrollPage> */}
      <ScrollPage
        style={{ height: "initial" }}
        className={navItemsIds.experience}
      >
        <ExperienceScrollPage1 />
      </ScrollPage>
      <ScrollPage>
        <ExperienceScrollPage2 />
      </ScrollPage>
      <ScrollPage>
        <ExperienceScrollPage3 />
      </ScrollPage>
      <ScrollPage>
        <ExperienceScrollPage4 />
      </ScrollPage>
      <ScrollPage></ScrollPage>
      <ScrollPage className={navItemsIds.adventures}>
        <FreestyleAdventures />
      </ScrollPage>
      <ScrollPage>
        <Grid container gap={5} justifyContent={"space-around"}>
          <Grid item>
            <AdventureCard adventure={adventures[3]} />
          </Grid>
        </Grid>
      </ScrollPage>
      <ScrollPage></ScrollPage>
      <ScrollPage
        style={{ height: "initial" }}
        className={navItemsIds.projects}
      >
        <MyProjects1 />
      </ScrollPage>
      <ScrollPage>
        <MyProjects2 />
      </ScrollPage>
      <ScrollPage>
        <MyProjects3 />
      </ScrollPage>
      <ScrollPage>
        <MyProjects4 />
      </ScrollPage>
      <ScrollPage>
        <MyProjects5 />
      </ScrollPage>
      {/* <ScrollPage>
        <MoreProjectsAndDetailsOnNotion />
      </ScrollPage> */}
      <ScrollPage></ScrollPage>
      <ScrollPage>
        <Animator animation={FadeUp} style={{ width: "100%" }}>
          <DigitalStore />
        </Animator>
      </ScrollPage>
      <ScrollPage></ScrollPage>
      <ScrollPage className={navItemsIds.freelancer}>
        <Animator animation={FadeUp} style={{ width: "100%" }}>
          <FreelanceAccounts />
        </Animator>
      </ScrollPage>
      <ScrollPage></ScrollPage>
      <ScrollPage className={navItemsIds.cv}>
        <Animator animation={FadeUp} style={{ width: "100%" }}>
          <DownloadCV />
        </Animator>
      </ScrollPage>
      <ScrollPage></ScrollPage>
      <ScrollPage className={navItemsIds.contactMe}>
        <Animator animation={FadeIn()} style={{ width: "100%" }}>
          <ContactMe />
        </Animator>
      </ScrollPage>
    </ScrollContainer>
  );
}

export default HomePage;

import { ThemeProvider, createTheme, useMediaQuery } from "@mui/material";
import "./App.scss";
import AnimatedCursorComponent from "./components/AnimatedCursorComponent";
import NavBar from "./components/NavBar";
import ParcticlesComponents from "./components/ParcticlesComponents";
import HomePage from "./pages/HomePage";
import { blue, green } from "@mui/material/colors";
import HomePageMobile from "./pages/HomePageMobile";

function App() {
  const isMobile = useMediaQuery("@media (max-width: 1000px)");

  const theme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: blue[700],
      },
      secondary: {
        main: green["A400"],
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      {!isMobile && <AnimatedCursorComponent />}
      {/* {!isMobile && <ParcticlesComponents />} */}
      <ParcticlesComponents />
      <NavBar />
      {isMobile ? <HomePageMobile /> : <HomePage />}
    </ThemeProvider>
  );
}

export default App;

import { BASE_URL } from "../utils/urls_utils";

export const adventures = [
  {
    name: "36 Hours Day",
    description: `The "36-Hours Day" app is designed to offer a novel perspective on time and encourage users to think differently about how they allocate their hours.`,
    image: `${BASE_URL}/adventures_images/the-36-hours-day.png`,
    link: "https://engelias.website/36-hours-day/",
    github: "https://github.com/Eng-Elias/36-Hours-Day",
    downloadLink: "https://github.com/Eng-Elias/36-Hours-Day/releases",
  },
  {
    name: "AR Adventures",
    description: (
      <div>
        Augmented Reality projects for fun: <br />
        <ul type="square">
          <li>AR Portfolio</li>
          <li>AR Yu-Gi-Oh Card</li>
          <li>AR Furniture</li>
          <li>AR Head Accessories</li>
        </ul>
      </div>
    ),
    image: `${BASE_URL}/adventures_images/augmented-reality.jpg`,
    link: "https://engelias.website/AR_adventures/",
    github: "",
    downloadLink: "",
  },
  {
    name: "CrewAI Visualizer",
    description:
      "This application provides a simplified user interface for leveraging the power of CrewAI, a cutting-edge framework for orchestrating role-playing autonomous AI agents.",
    image: `${BASE_URL}/adventures_images/crewai_visualizer.png`,
    link: "",
    github: "https://github.com/Eng-Elias/CrewAI-Visualizer",
    downloadLink: "",
  },
  {
    name: "Comming Soon",
    description: (
      <p style={{ textAlign: "center", fontSize: 20 }}>
        ❔🌟❔ <br />
        ⁉️💭⁉️ <br />
        ⁉️❔
      </p>
    ),
    image: `${BASE_URL}/adventures_images/coming.jpg`,
    link: "",
    github: "",
    downloadLink: "",
  },
];

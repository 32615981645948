import { Grid, Typography } from "@mui/material";
import React from "react";
import { BASE_URL } from "../utils/urls_utils";
import { ProjectCard } from "../sections/MyProjects";
// import WordPressBlogProject from "./wordpress_projects/WordPressBlogProject";
import WordPressBusinessWebsiteProject from "./wordpress_projects/WordPressBusinessWebsiteProject";
import WordPressCharityProject from "./wordpress_projects/WordPressCharityProject";
import WordPressAffiliateProject from "./wordpress_projects/WordPressAffiliateProject";

const wordpressProjects = [
  // {
  //   name: "My Blog",
  //   description: "To share my thoughts and experiences",
  //   image: `${BASE_URL}/projects_images/wordpress/blog.png`,
  //   component: <WordPressBlogProject />,
  // },
  {
    name: "Business Website",
    description: "For a Software and Hardware Solutions Company",
    image: `${BASE_URL}/projects_images/wordpress/business.png`,
    component: <WordPressBusinessWebsiteProject />,
  },
  {
    name: "Charity Website",
    description: "Non-profit/Donation/Charity website",
    image: `${BASE_URL}/projects_images/wordpress/charity.jpg`,
    component: <WordPressCharityProject />,
  },
  {
    name: "Affiliate Marketing",
    description: "Affiliate Marketing Blog to get passive income",
    image: `${BASE_URL}/projects_images/wordpress/affiliate.png`,
    component: <WordPressAffiliateProject />,
  },
];

function WordPressProjects() {
  return (
    <Grid sx={{ backgroundColor: "#282828f7", p: 2 }}>
      <Grid container justifyContent={"center"} sx={{ my: 3 }}>
        <Typography variant="h3" color={"white"}>
          WordPress Projects
        </Typography>
      </Grid>
      <Grid container gap={5} justifyContent={"center"} sx={{ my: 5 }}>
        {wordpressProjects.map((project, i) => (
          <Grid item key={i}>
            <ProjectCard project={project} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default WordPressProjects;

import {
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Tabs from "@mui/base/Tabs";
import {
  StyledTab,
  StyledTabPanel,
  StyledTabsList,
} from "../../styles/styled_tabs";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ExtensionIcon from "@mui/icons-material/Extension";

function WordPressAffiliateProject() {
  const isMobile = useMediaQuery("@media (max-width: 1000px)");

  return (
    <Tabs defaultValue={0}>
      <StyledTabsList>
        <StyledTab value={0}>Gallery</StyledTab>
        <StyledTab value={1}>Features</StyledTab>
        <StyledTab value={2}>Plugins</StyledTab>
      </StyledTabsList>
      <StyledTabPanel value={0}>
        <iframe
          height={isMobile ? "300" : "500"}
          style={{ width: "100%" }}
          src="https://www.youtube.com/embed/3caXKIU0nvw"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </StyledTabPanel>
      <StyledTabPanel value={1}>
        <Grid sx={{ color: "#ffffff", px: 2 }}>
          <Typography variant="h4">Overview:</Typography>
          <Divider />
          <Typography>
            <Link href="https://engelias.website/affiliate">
              Affiliate Marketing Website
            </Link>{" "}
            is useful to earn money by blogging. you just need to write articles
            about some products and set affiliate links from the sellers and
            then for each user buys some these products by you links you will
            earn some money.
          </Typography>
          <br />
          <Typography variant="h4">Features:</Typography>
          <Divider />
          <List>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>Modern landing page.</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>
                Use Elementor and Envato elements to build article very fast.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>
                Social sharing buttons to get more visitors.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>
                Use Yoast SEO plugins to take your blog to the first page of
                Google.
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
      </StyledTabPanel>
      <StyledTabPanel value={2}>
        <Grid sx={{ color: "#ffffff", px: 2 }}>
          <Typography variant="h4">WordPress Plugins:</Typography>
          <Divider />
          <List>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>All In One WP Security</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>All-in-One WP Migration</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>Elementor</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>Envato Elements</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>Elementor Header & Footer Builder</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>Essential Addons for Elementor</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>LiteSpeed Cache</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>Pretty Links</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>Sidebar Manager</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>
                Social Media and Share Icons (Ultimate Social Media)
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>WP Sticky Sidebar</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>WPForms Lite</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>Yoast SEO</ListItemText>
            </ListItem>
          </List>
        </Grid>
      </StyledTabPanel>
    </Tabs>
  );
}

export default WordPressAffiliateProject;

import ImageGallery from "react-image-gallery";
import { BASE_URL } from "../utils/urls_utils";
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Tabs from "@mui/base/Tabs";
import {
  StyledTab,
  StyledTabPanel,
  StyledTabsList,
} from "../styles/styled_tabs";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ExtensionIcon from "@mui/icons-material/Extension";

function RealEstateElectronProject() {
  const thumbnailHeight = 100;
  const thumbnailWidth = 100;

  const images = [
    {
      original: `${BASE_URL}/projects_images/real_estate/1.png`,
      thumbnail: `${BASE_URL}/projects_images/real_estate/1.png`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
    {
      original: `${BASE_URL}/projects_images/real_estate/2.png`,
      thumbnail: `${BASE_URL}/projects_images/real_estate/2.png`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
    {
      original: `${BASE_URL}/projects_images/real_estate/3.png`,
      thumbnail: `${BASE_URL}/projects_images/real_estate/3.png`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
    {
      original: `${BASE_URL}/projects_images/real_estate/4.png`,
      thumbnail: `${BASE_URL}/projects_images/real_estate/4.png`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
    {
      original: `${BASE_URL}/projects_images/real_estate/5.png`,
      thumbnail: `${BASE_URL}/projects_images/real_estate/5.png`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
  ];

  return (
    <Tabs defaultValue={0}>
      <StyledTabsList>
        <StyledTab value={0}>Gallery</StyledTab>
        <StyledTab value={1}>Features</StyledTab>
        <StyledTab value={2}>Packages</StyledTab>
      </StyledTabsList>
      <StyledTabPanel value={0}>
        <ImageGallery
          items={images}
          autoPlay
          showFullscreenButton={false}
          showThumbnails={false}
          //   showBullets
          //   thumbnailPosition="left"
        />
      </StyledTabPanel>
      <StyledTabPanel value={1}>
        <Grid sx={{ color: "#ffffff", px: 2 }}>
          <Typography variant="h4">Overview:</Typography>
          <Divider />
          <Typography>
            Real Estate Electron is a real estates management desktop
            application developed for a company in the UAE “Mallak Properties”
            which is real estate brokerage company.
          </Typography>
          <br />
          <Typography variant="h4">Features:</Typography>
          <Divider />
          <List>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>multi-user environment</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>
                trial version works for 15 days then you need activationjoin
                room request then accept/reject by admin
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>Enter, edit, delete and search data</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>
                table view for each components with searching and pagination
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>show specific statistics</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>show yearly statistics</ListItemText>
            </ListItem>
          </List>
        </Grid>
      </StyledTabPanel>
      <StyledTabPanel value={2}>
        <Grid sx={{ color: "#ffffff", px: 2 }}>
          <Typography variant="h4">Technologies:</Typography>
          <Divider />
          <Typography>
            Node.js + Express + EJS + PostgreSQL + Electron.js
          </Typography>
          <br />
          <Typography variant="h4">Packages:</Typography>
          <Divider />
          <List>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>bcryptjs</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>express + ejs + body-parser</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>knex + pg + objection + Q</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>multer + path</ListItemText>
            </ListItem>
          </List>
        </Grid>
      </StyledTabPanel>
    </Tabs>
  );
}

export default RealEstateElectronProject;

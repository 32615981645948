import { Grid, Typography } from "@mui/material";
import React from "react";

function DigitalStore() {
  return (
    <Grid sx={{ mx: "auto", width: "75%", color: "white" }}>
      <Grid>
        <Typography
          h1
          className="neon-text animated-box"
          neontext=""
          sx={{ textAlign: "center", fontSize: "2rem", mb: "1rem" }}
        >
          My Digital Store
        </Typography>
      </Grid>
      <Grid container>
        <div class="relative z-20 flex items-center bg-white dark:bg-gray-800 mx-auto rounded-lg">
          <div class="container relative flex flex-col items-center justify-between px-6 py-8 mx-auto">
            <div class="flex flex-col">
              <p class="w-full text-2xl font-light text-center text-gray-800 capitalize dark:text-white">
                Explore my digital store on Gumroad, showcasing a diverse range
                of innovative projects in software and prompt engineering.
              </p>
              <div class="flex items-center justify-center mt-4">
                <a
                  href="https://engelias.gumroad.com/"
                  target="_blank"
                  rel="noreferrer"
                  class="px-4 py-2 mr-4 text-white uppercase bg-gray-800 border-2 border-transparent text-md hover:bg-gray-900 ring-2 ring-blue-500 rounded-lg"
                >
                  Discover
                </a>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default DigitalStore;

import {
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Tabs from "@mui/base/Tabs";
import {
  StyledTab,
  StyledTabPanel,
  StyledTabsList,
} from "../styles/styled_tabs";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ExtensionIcon from "@mui/icons-material/Extension";

function GenophoreProject() {
  return (
    <Tabs defaultValue={1}>
      <StyledTabsList>
        <StyledTab value={1}>Overview</StyledTab>
        <StyledTab value={2}>Packages</StyledTab>
      </StyledTabsList>
      <StyledTabPanel value={1}>
        <Grid sx={{ color: "#ffffff", px: 2 }}>
          <Typography variant="h4">Overview:</Typography>
          <Divider />
          <Typography>
            I worked with <Link href="https://genophore.com/">Genophore</Link>{" "}
            company for a year as a full stack developer. it is a bioinformatic
            company.
          </Typography>
          <br />
          <Typography variant="h4">My tasks in Genophore:</Typography>
          <Divider />
          <List>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>develop backend API using Django</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>
                develop frontend UI/UX using Genophore’s JQuery-based framework
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>develop Celery tasks when needed</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>
                use third-party libraries especially bioinformatic libraries
                like BioPython, Mol*, SeqViz, TeslaGenOpenVectorEditor, …..
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>
                update forked version of SeqViz and TeslaGen/OpenVectorEditor to
                add new features which aren’t in Github version, then upload the
                new version to S3 to use it in Genophore project.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>
                use TypeScript to develop new plugins for Plate.js which is rich
                text editor plugin system for Slate & React.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>
                search for biology information in some platforms like: RCSB,
                EBI, IMGT, …..
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
      </StyledTabPanel>
      <StyledTabPanel value={2}>
        <Grid sx={{ color: "#ffffff", px: 2 }}>
          <Typography variant="h4">Technologies:</Typography>
          <Divider />
          <Typography>
            Django + JQuery + React + Gitlab + Docker + AWS + Elastic Search +
            Celery Tasks
          </Typography>
          <br />
          <Typography variant="h4">Featured Packages:</Typography>
          <Divider />
          <List>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>Django + django-rest-framework</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>BioPython + BioParser.js</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>
                SeqViz + TeslaGen/OpenVectorEditor + Mol*
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>Plate.js</ListItemText>
            </ListItem>
          </List>
        </Grid>
      </StyledTabPanel>
    </Tabs>
  );
}

export default GenophoreProject;

import { Grid, Link, Typography } from "@mui/material";
import React from "react";

const freelanceAccounts = [
  {
    text: "Mostaql مستقل",
    url: "https://mostaql.com/u/eliasowis",
  },
  {
    text: "UpWork",
    url: "https://www.upwork.com/freelancers/~010857c3e6cfd643ad",
  },
  {
    text: "Freelancer",
    url: "https://www.freelancer.com/u/eliasowis",
  },
  {
    text: "PeoplePerHour",
    url: "https://www.peopleperhour.com/freelancer/technology-programming/elias-owis-full-stack-developer-nzzzjny",
  },
  {
    text: "Legiit",
    url: "https://legiit.com/eliasowis",
  },
];

function FreelanceAccounts() {
  return (
    <>
      <Grid sx={{ my: 5 }}>
        <Typography
          h1
          className="neon-text animated-box"
          neontext=""
          sx={{
            textAlign: "center",
            fontSize: "2.5rem",
            width: "75%",
            mx: "auto",
          }}
        >
          My Freelance Accounts
        </Typography>
      </Grid>
      <div className="pulse-list-container">
        <ul className="pulse-list">
          {freelanceAccounts.map((account, i) => (
            <li key={i}>
              <Link href={account.url} target="_blank" color={"#ffd700"}>
                {account.text}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default FreelanceAccounts;

import { Grid, Typography } from "@mui/material";
import React from "react";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";

function MyJourney() {
  return (
    <Grid
      className="animated-box"
      sx={{ mx: "auto", width: "75%", color: "white" }}
    >
      <Grid>
        <Typography
          h1
          className="neon-text"
          neontext=""
          sx={{ textAlign: "center", fontSize: "2rem", mb: "1rem" }}
        >
          My Journey
        </Typography>
      </Grid>
      <Grid container className="box-text" spacing={5}>
        <Grid item className="my-journey-text">
          <FollowTheSignsIcon color="secondary" fontSize="large" />I started my
          journey as a web developer with Node.js, Django, Angular and React,
          <GolfCourseIcon color="secondary" fontSize="medium" />
          and from there, I dove into the world of desktop applications with
          Electron.js.
          <GolfCourseIcon color="secondary" fontSize="medium" /> I was
          fascinated by the potential of AI, and soon enough, I was integrating
          it into my projects using Python, ML libraies and AI APIs.{" "}
          <GolfCourseIcon color="secondary" fontSize="medium" /> As my skills
          grew, I started exploring other avenues like WordPress for blogging
          and eCommerce, and Odoo for ERP projects.{" "}
          <GolfCourseIcon color="secondary" fontSize="medium" /> I even learned
          how to manage large-scale projects and improve teamwork using various
          software management tools.{" "}
          <GolfCourseIcon color="secondary" fontSize="medium" /> As my journey
          continued, I picked up mobile app development skills with React
          Native. <GolfCourseIcon color="secondary" fontSize="medium" /> And one
          of the interesting features I learned is Web AR/VR.{" "}
          <GolfCourseIcon color="secondary" fontSize="medium" /> With the advent
          of the latest AI revolution, I gained an understanding of prompt
          engineering techniques, enabling me to maximize the benefits from LLM
          and AGI. <GolfCourseIcon color="secondary" fontSize="medium" />
          However I always strive to improve myself to build software for any
          purpose. <GolfCourseIcon color="secondary" fontSize="medium" /> In
          addition, I've been using various productivity tools like Notion to
          organize my life and maximize my efficiency.{" "}
          <GolfCourseIcon color="secondary" fontSize="medium" />
          <br /> I believe in continuous learning and improvement, and I'm
          excited to see where my journey takes me next.
          <AutoGraphIcon color="secondary" fontSize="large" />
          <AutoStoriesIcon color="secondary" fontSize="large" />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MyJourney;

import ImageGallery from "react-image-gallery";
import { BASE_URL } from "../../utils/urls_utils";
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Tabs from "@mui/base/Tabs";
import {
  StyledTab,
  StyledTabPanel,
  StyledTabsList,
} from "../../styles/styled_tabs";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ExtensionIcon from "@mui/icons-material/Extension";

function OdooRestaurantProject() {
  const thumbnailHeight = 100;
  const thumbnailWidth = 100;

  const images = [
    {
      original: `${BASE_URL}/projects_images/odoo/pos.jpg`,
      thumbnail: `${BASE_URL}/projects_images/odoo/pos.jpg`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
    {
      original: `${BASE_URL}/projects_images/odoo/restaurant.gif`,
      thumbnail: `${BASE_URL}/projects_images/odoo/restaurant.gif`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
  ];

  return (
    <Tabs defaultValue={0}>
      <StyledTabsList>
        <StyledTab value={0}>Gallery</StyledTab>
        <StyledTab value={1}>Features</StyledTab>
        <StyledTab value={2}>Modules</StyledTab>
      </StyledTabsList>
      <StyledTabPanel value={0}>
        <ImageGallery
          items={images}
          autoPlay
          showFullscreenButton={false}
          showThumbnails={false}
          //   showBullets
          //   thumbnailPosition="left"
        />
      </StyledTabPanel>
      <StyledTabPanel value={1}>
        <Grid sx={{ color: "#ffffff", px: 2 }}>
          <Typography variant="h4">Overview:</Typography>
          <Divider />
          <Typography>
            By using Odoo Restaurant module and POS app I could build restaurant
            management system. you have 2 choices:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>Point of sale: to sell fast food.</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>
                Tables: to set floors of the restaurant and distribute tables of
                each floor then you can manage orders of each table.
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
      </StyledTabPanel>
      <StyledTabPanel value={2}>
        <Grid sx={{ color: "#ffffff", px: 2 }}>
          <Typography variant="h4">Odoo Apps & Modules:</Typography>
          <Divider />
          <List>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>Point of sales</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>Restaurant</ListItemText>
            </ListItem>
          </List>
        </Grid>
      </StyledTabPanel>
    </Tabs>
  );
}

export default OdooRestaurantProject;

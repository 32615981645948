import { Grid, Link, Typography } from "@mui/material";
import React from "react";
import { BASE_URL } from "../utils/urls_utils";
import { ProjectCard } from "../sections/MyProjects";
import OdooHRProject from "./odoo_projects/OdooHRProject";
import OdooRestaurantProject from "./odoo_projects/OdooRestaurantProject";
import OdooBusinessIntelligenceProject from "./odoo_projects/OdooBusinessIntelligence";
import OdooELearningProject from "./odoo_projects/OdooELearningProject";
import OdooBusinessWebsiteProject from "./odoo_projects/OdooBusinessWebsiteProject";
import OdooAutoPartsProject from "./odoo_projects/OdooAutoParts";

const odooProjects = [
  {
    name: "Auto Parts",
    description: "Auto Parts ERP system",
    image: `${BASE_URL}/projects_images/odoo/auto_parts.jpg`,
    component: <OdooAutoPartsProject />,
  },
  {
    name: "HR",
    description: "HR management system",
    image: `${BASE_URL}/projects_images/odoo/hr.gif`,
    component: <OdooHRProject />,
  },
  {
    name: "Restaurant",
    description: "POS & Restaurant management system",
    image: `${BASE_URL}/projects_images/odoo/restaurant.gif`,
    component: <OdooRestaurantProject />,
  },
  {
    name: "Business Intelligence",
    description: "Odoo Dashboards statistics",
    image: `${BASE_URL}/projects_images/odoo/dashboards.png`,
    component: <OdooBusinessIntelligenceProject />,
  },
  {
    name: "eLearning",
    description: "eLearning management system",
    image: `${BASE_URL}/projects_images/odoo/elearning.png`,
    component: <OdooELearningProject />,
  },
  {
    name: "Business & eCommerce",
    description: "Business Website + eCommerce Store",
    image: `${BASE_URL}/projects_images/odoo/business.png`,
    component: <OdooBusinessWebsiteProject />,
  },
];

function OdooProjects() {
  return (
    <Grid sx={{ backgroundColor: "#282828f7", p: 2 }}>
      <Grid
        container
        direction={"column"}
        justifyContent={"center"}
        sx={{ my: 3 }}
      >
        <Grid>
          <Typography variant="h3" color={"white"}>
            Odoo Projects
          </Typography>
        </Grid>
        <Grid>
          <Typography variant="h4" color={"white"}>
            I am a Living Legend on{" "}
            <Link href="https://www.odoo.com/slides" target="_blank">
              {" "}
              Odoo Tutorials
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <Grid container gap={5} justifyContent={"center"} sx={{ my: 5 }}>
        {odooProjects.map((project, i) => (
          <Grid item key={i}>
            <ProjectCard project={project} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default OdooProjects;

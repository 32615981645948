import { Grid, Tooltip } from "@mui/material";
import { Tilt } from "react-tilt";
import { BASE_URL } from "../utils/urls_utils";

const skillsIcons = {
  notion: `${BASE_URL}/skills_icons/notion.svg`,
  github: `${BASE_URL}/skills_icons/github.svg`,
  atlassian: `${BASE_URL}/skills_icons/atlassian.png`,
  gitlab: `${BASE_URL}/skills_icons/gitlab.svg`,
  docker: `${BASE_URL}/skills_icons/docker.svg`,
  nginx: `${BASE_URL}/skills_icons/nginx.svg`,
  apache: `${BASE_URL}/skills_icons/apache.svg`,
  graphql: `${BASE_URL}/skills_icons/graphql.svg`,
  python: `${BASE_URL}/skills_icons/python.svg`,
  cpp: `${BASE_URL}/skills_icons/cpp.svg`,
  js: `${BASE_URL}/skills_icons/javascript.svg`,
  ts: `${BASE_URL}/skills_icons/typescript.svg`,
  django: `${BASE_URL}/skills_icons/django.svg`,
  nodejs: `${BASE_URL}/skills_icons/node_js.svg`,
  electronjs: `${BASE_URL}/skills_icons/electron_js.svg`,
  angular: `${BASE_URL}/skills_icons/angular.svg`,
  react: `${BASE_URL}/skills_icons/react.svg`,
  reactNative: `${BASE_URL}/skills_icons/react_native.svg`,
  sql: `${BASE_URL}/skills_icons/sql-database.svg`,
  mongodb: `${BASE_URL}/skills_icons/mongodb.svg`,
  postgresql: `${BASE_URL}/skills_icons/postgresql.svg`,
  odoo: `${BASE_URL}/skills_icons/odoo.png`,
  wordpress: `${BASE_URL}/skills_icons/wordpress.svg`,
  selenium: `${BASE_URL}/skills_icons/selenium.svg`,
  seo: `${BASE_URL}/skills_icons/seo.svg`,
  openai: `${BASE_URL}/skills_icons/openai.svg`,
  gemini: `${BASE_URL}/skills_icons/google-gemini.svg`,
};

const skills = [
  { text: "Notion", url: skillsIcons.notion },
  { text: "Github", url: skillsIcons.github },
  { text: "Atlassian", url: skillsIcons.atlassian },
  { text: "Gitlab", url: skillsIcons.gitlab },
  { text: "Docker", url: skillsIcons.docker },
  { text: "Nginx", url: skillsIcons.nginx },
  { text: "Apache", url: skillsIcons.apache },
  { text: "GraphQL", url: skillsIcons.graphql },
  { text: "Python", url: skillsIcons.python },
  { text: "C++", url: skillsIcons.cpp },
  { text: "JavaScript", url: skillsIcons.js },
  { text: "TypeScript", url: skillsIcons.ts },
  { text: "Django", url: skillsIcons.django },
  { text: "Node.js", url: skillsIcons.nodejs },
  { text: "Electron.js", url: skillsIcons.electronjs },
  { text: "Angular", url: skillsIcons.angular },
  { text: "React", url: skillsIcons.react },
  { text: "React Native", url: skillsIcons.reactNative },
  { text: "SQL", url: skillsIcons.sql },
  { text: "MongoDB", url: skillsIcons.mongodb },
  { text: "PostgreSQL", url: skillsIcons.postgresql },
  { text: "Odoo", url: skillsIcons.odoo },
  { text: "WordPress", url: skillsIcons.wordpress },
  { text: "Selenium", url: skillsIcons.selenium },
  { text: "SEO", url: skillsIcons.seo },
  { text: "OpenAI", url: skillsIcons.openai },
  { text: "Gemini", url: skillsIcons.gemini },
];

function Skills() {
  return (
    <Tilt
      options={{
        max: 20,
        scale: 1,
        perspective: 700,
      }}
    >
      <Grid
        container
        justifyItems={"center"}
        sx={{
          width: "90%",
          mx: "auto",
          p: "1rem",
        }}
      >
        <div className="mx-auto flex flex-wrap gap-12">
          {skills.map((skill) => (
            <Tooltip title={skill.text} placement="top">
              <div className="block-container w-16 h-16" key={skill.text}>
                <div
                  className="btn-back rounded-xl"
                  style={{
                    background:
                      "linear-gradient(135deg, #0d47a1 -20%, #0d47a1 120%)",
                  }}
                />
                <div className="btn-front rounded-xl flex justify-center items-center">
                  <img
                    src={skill.url}
                    alt={skill.text}
                    className="w-1/2 h-1/2 object-contain"
                  />
                </div>
              </div>
            </Tooltip>
          ))}
        </div>
      </Grid>
    </Tilt>
  );
}

export default Skills;

import AnimatedCursor from "react-animated-cursor";

function AnimatedCursorComponent() {
  return (
    <AnimatedCursor
      color="255,255,255"
      innerSize={8}
      outerSize={30}
      innerScale={1}
      outerScale={1.5}
      outerAlpha={0.5}
      innerStyle={{
        border: "3px solid #292a2c",
        zIndex: 9999,
      }}
      outerStyle={{
        border: "3px solid #292a2c",
        zIndex: 9999,
      }}
    />
  );
}

export default AnimatedCursorComponent;

export default class CopyUtils {
  static fallbackCopyTextToClipboard({ text, onSuccess, onError }) {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      const msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
      if (successful) {
        onSuccess();
      } else {
        onError();
      }
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
      onError();
    }

    document.body.removeChild(textArea);
  }

  static copyTextToClipboard({
    text,
    onSuccess = () => {},
    onError = () => {},
  }) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard({ text, onSuccess, onError });
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        onSuccess();
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
        onError();
      }
    );
  }
}

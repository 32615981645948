import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import GitHubIcon from "@mui/icons-material/GitHub";
import DownloadIcon from "@mui/icons-material/Download";
import { adventures } from "../data/adventures";

export function AdventureCard({ adventure }) {
  if (!adventure) return null;
  const { name, description, image, link, github, downloadLink } = adventure;
  return (
    <Card sx={{ maxWidth: 300 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          width={300}
          height={200}
          image={image}
          alt={name}
        />
        <CardContent sx={{ minHeight: 150 }}>
          <Typography gutterBottom variant="h5" component="div">
            {name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
        <CardActions sx={{ height: 35 }}>
          {link && (
            <IconButton href={link} target="_blank">
              <LaunchIcon />
            </IconButton>
          )}
          {github && (
            <IconButton href={github} target="_blank">
              <GitHubIcon />
            </IconButton>
          )}
          {downloadLink && (
            <IconButton href={downloadLink} target="_blank">
              <DownloadIcon />
            </IconButton>
          )}
        </CardActions>
      </CardActionArea>
    </Card>
  );
}

export function FreestyleAdventures() {
  const isMobile = useMediaQuery("@media (max-width: 1000px)");

  return (
    <>
      <Grid sx={{ my: 5 }}>
        <Typography
          h1
          className="neon-text animated-box"
          neontext=""
          sx={{
            textAlign: "center",
            fontSize: "2.5rem",
            width: "75%",
            mx: "auto",
          }}
        >
          My Freestyle Adventures
        </Typography>
      </Grid>
      <Grid container gap={5} justifyContent={"space-around"} sx={{ my: 5 }}>
        <Grid item>
          <AdventureCard adventure={adventures[0]} />
        </Grid>
        <Grid item>
          <AdventureCard adventure={adventures[1]} />
        </Grid>
        <Grid item>
          <AdventureCard adventure={adventures[2]} />
        </Grid>
        {isMobile && (
          <Grid item>
            <AdventureCard adventure={adventures[3]} />
          </Grid>
        )}
      </Grid>
    </>
  );
}

import {
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Tabs from "@mui/base/Tabs";
import {
  StyledTab,
  StyledTabPanel,
  StyledTabsList,
} from "../../styles/styled_tabs";
import ExtensionIcon from "@mui/icons-material/Extension";

function OdooAutoPartsProject() {
  return (
    <Tabs defaultValue={1}>
      <StyledTabsList>
        <StyledTab value={1}>Features</StyledTab>
        <StyledTab value={2}>Modules</StyledTab>
      </StyledTabsList>
      <StyledTabPanel value={1}>
        <Grid sx={{ color: "#ffffff", px: 2, pb: 3 }}>
          <Typography variant="h4">Overview:</Typography>
          <Divider />
          <Typography>
            Successfully customized Odoo for an Auto Parts company in Yemen,
            streamlining operations across branches and devices. Optimized
            management of various business aspects, replacing outdated systems
            and paperwork. Achieved high client satisfaction, garnering a 5-star
            rating on{" "}
            <Link href="https://mostaql.com/u/eliasowis/reviews/6303065">
              Mostaql
            </Link>
            .
          </Typography>
        </Grid>
      </StyledTabPanel>
      <StyledTabPanel value={2}>
        <Grid sx={{ color: "#ffffff", px: 2 }}>
          <Typography variant="h4">Odoo Apps & Modules:</Typography>
          <Divider />
          <List>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>Sales</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>Invoicing</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>CRM</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>Inventory</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>Purchase</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>Point of Sale</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>Employees</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>
                Odoo 16 Full Accounting Kit (custom module developed by Cybrosys
                Technologies Pvt. Ltd.)
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>
                Auto Parts Base (custom module developed by ErpMstar Solutions)
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>
                Pos Auto Parts (custom module developed by ErpMstar Solutions)
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
      </StyledTabPanel>
    </Tabs>
  );
}

export default OdooAutoPartsProject;

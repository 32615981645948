import ImageGallery from "react-image-gallery";
import { BASE_URL } from "../utils/urls_utils";
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Tabs from "@mui/base/Tabs";
import {
  StyledTab,
  StyledTabPanel,
  StyledTabsList,
} from "../styles/styled_tabs";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ExtensionIcon from "@mui/icons-material/Extension";

function VideoRoomsProject() {
  const thumbnailHeight = 100;
  const thumbnailWidth = 100;

  const images = [
    {
      original: `${BASE_URL}/projects_images/video_rooms/1.png`,
      thumbnail: `${BASE_URL}/projects_images/video_rooms/1.png`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
    {
      original: `${BASE_URL}/projects_images/video_rooms/2.png`,
      thumbnail: `${BASE_URL}/projects_images/video_rooms/2.png`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
    {
      original: `${BASE_URL}/projects_images/video_rooms/3.png`,
      thumbnail: `${BASE_URL}/projects_images/video_rooms/3.png`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
    {
      original: `${BASE_URL}/projects_images/video_rooms/4.png`,
      thumbnail: `${BASE_URL}/projects_images/video_rooms/4.png`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
    {
      original: `${BASE_URL}/projects_images/video_rooms/5.png`,
      thumbnail: `${BASE_URL}/projects_images/video_rooms/5.png`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
    {
      original: `${BASE_URL}/projects_images/video_rooms/6.png`,
      thumbnail: `${BASE_URL}/projects_images/video_rooms/6.png`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
    {
      original: `${BASE_URL}/projects_images/video_rooms/7.png`,
      thumbnail: `${BASE_URL}/projects_images/video_rooms/7.png`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
    {
      original: `${BASE_URL}/projects_images/video_rooms/8.png`,
      thumbnail: `${BASE_URL}/projects_images/video_rooms/8.png`,
      thumbnailHeight: thumbnailHeight,
      thumbnailWidth: thumbnailWidth,
    },
  ];

  return (
    <Tabs defaultValue={0}>
      <StyledTabsList>
        <StyledTab value={0}>Gallery</StyledTab>
        <StyledTab value={1}>Features</StyledTab>
        <StyledTab value={2}>Packages</StyledTab>
      </StyledTabsList>
      <StyledTabPanel value={0}>
        <ImageGallery
          items={images}
          autoPlay
          showFullscreenButton={false}
          showThumbnails={false}
          //   showBullets
          //   thumbnailPosition="left"
        />
      </StyledTabPanel>
      <StyledTabPanel value={1}>
        <Grid sx={{ color: "#ffffff", px: 2 }}>
          <Typography variant="h4">Overview:</Typography>
          <Divider />
          <Typography>
            Video Rooms is a video party app where you can upload videos and
            watch them with your friends at the same time and at the same moment
            of video.
          </Typography>
          <br />
          <Typography variant="h4">Features:</Typography>
          <Divider />
          <List>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>create room</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>
                join room request then accept/reject by admin
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>upload video</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>
                synchronized watch video by room's members
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>chat while watching video</ListItemText>
            </ListItem>
          </List>
        </Grid>
      </StyledTabPanel>
      <StyledTabPanel value={2}>
        <Grid sx={{ color: "#ffffff", px: 2 }}>
          <Typography variant="h4">Technologies:</Typography>
          <Divider />
          <Typography>Node.js + Express + EJS + MongoDB + Socket.io</Typography>
          <br />
          <Typography variant="h4">Packages:</Typography>
          <Divider />
          <List>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>bcryptjs</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>
                express + ejs + body-parser + express-session +
                express-validator
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>
                mongodb + mongoose + connect-flash + connect-mongodb-session
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>multer + path</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>socket.io</ListItemText>
            </ListItem>
          </List>
        </Grid>
      </StyledTabPanel>
    </Tabs>
  );
}

export default VideoRoomsProject;

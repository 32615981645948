import {
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Tabs from "@mui/base/Tabs";
import {
  StyledTab,
  StyledTabPanel,
  StyledTabsList,
} from "../../styles/styled_tabs";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ExtensionIcon from "@mui/icons-material/Extension";

function WordPressCharityProject() {
  const isMobile = useMediaQuery("@media (max-width: 1000px)");

  return (
    <Tabs defaultValue={0}>
      <StyledTabsList>
        <StyledTab value={0}>Gallery</StyledTab>
        <StyledTab value={1}>Features</StyledTab>
        <StyledTab value={2}>Plugins</StyledTab>
      </StyledTabsList>
      <StyledTabPanel value={0}>
        <iframe
          height={isMobile ? "300" : "500"}
          style={{ width: "100%" }}
          src="https://www.youtube.com/embed/DZ9pXPGDxsA"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </StyledTabPanel>
      <StyledTabPanel value={1}>
        <Grid sx={{ color: "#ffffff", px: 2 }}>
          <Typography variant="h4">Overview:</Typography>
          <Divider />
          <Typography>
            Charity Website is useful to get donations to help people or create
            projects and can be customized to be suitable for any charity
            activity.
            <br />
            Visit the website:{" "}
            <Link href="https://engelias.website/donator/">
              Charity Website
            </Link>
          </Typography>
          <br />
          <Typography variant="h4">Features:</Typography>
          <Divider />
          <List>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>
                Create campaign: you can create new campaign for each charity
                activity and you can customize each campaign as you want.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>
                User account: user can use his account to review his donation
                history.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>
                Anonymous donations: users can donate without creating account.
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
      </StyledTabPanel>
      <StyledTabPanel value={2}>
        <Grid sx={{ color: "#ffffff", px: 2 }}>
          <Typography variant="h4">WordPress Plugins:</Typography>
          <Divider />
          <List>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>All In One WP Security</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>All-in-One WP Migration</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>Elementor</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>Elementor Header & Footer Builder</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>Essential Addons for Elementor</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>LiteSpeed Cache</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>Give - Donation Plugin</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>GiveWP Donation Widgets for Elementor</ListItemText>
            </ListItem>
          </List>
        </Grid>
      </StyledTabPanel>
    </Tabs>
  );
}

export default WordPressCharityProject;

import {
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Tabs from "@mui/base/Tabs";
import {
  StyledTab,
  StyledTabPanel,
  StyledTabsList,
} from "../../styles/styled_tabs";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ExtensionIcon from "@mui/icons-material/Extension";

function OdooELearningProject() {
  return (
    <Tabs defaultValue={1}>
      <StyledTabsList>
        <StyledTab value={1}>Features</StyledTab>
        <StyledTab value={2}>Modules</StyledTab>
      </StyledTabsList>
      <StyledTabPanel value={1}>
        <Grid sx={{ color: "#ffffff", px: 2, pb: 3 }}>
          <Typography variant="h4">Overview:</Typography>
          <Divider />
          <Typography>
            By using eLearning app I could build eLearning system similar to
            <Link href="https://www.odoo.com/slides"> Odoo Tutorials</Link>.
          </Typography>
          <br />
          <Typography variant="h4">Features:</Typography>
          <Divider />
          <List>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>
                Course Management: The Odoo eLearning app allows you to create
                and manage online courses, including organizing course content,
                setting up quizzes and exams, and tracking student progress.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>
                Student Management: The app provides a centralized platform for
                managing student information, including student profiles,
                enrolments, and payment details.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>
                Forum: Create one or more forums linked to your course and let
                the members of your community help each other.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>
                Learning Management System (LMS): The eLearning app includes a
                powerful LMS that allows you to track student progress, assign
                grades, and generate reports on student performance.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>
                Certification: The eLearning app provides the ability to issue
                certifications to students who complete courses, helping to
                demonstrate their competencies and knowledge.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "gold" }} />
              </ListItemIcon>
              <ListItemText>
                Multi-Language Support: The eLearning app is available in
                multiple languages, making it accessible to students and
                educators around the world.
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
      </StyledTabPanel>
      <StyledTabPanel value={2}>
        <Grid sx={{ color: "#ffffff", px: 2 }}>
          <Typography variant="h4">Odoo Apps & Modules:</Typography>
          <Divider />
          <List>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>eLearning</ListItemText>
            </ListItem>
          </List>
        </Grid>
      </StyledTabPanel>
    </Tabs>
  );
}

export default OdooELearningProject;

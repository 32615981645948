import {
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Tabs from "@mui/base/Tabs";
import {
  StyledTab,
  StyledTabPanel,
  StyledTabsList,
} from "../styles/styled_tabs";
import ExtensionIcon from "@mui/icons-material/Extension";

function SearchEngineSpiderProject() {
  return (
    <Tabs defaultValue={0}>
      <StyledTabsList>
        <StyledTab value={0}>Docs</StyledTab>
        <StyledTab value={1}>Features</StyledTab>
      </StyledTabsList>
      <StyledTabPanel value={0}>
        <Grid sx={{ color: "#ffffff", px: 2 }}>
          <Typography variant="h4">Docs:</Typography>
          <Divider color="#003A75" />
          <List>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>
                <Typography>
                  Explore code on GitHub:{" "}
                  <Link
                    target="_blank"
                    href="https://github.com/Eng-Elias/search_engine_spider"
                  >
                    https://github.com/Eng-Elias/search_engine_spider
                  </Link>
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>
                <Typography>
                  Read Medium article:{" "}
                  <Link
                    target="_blank"
                    href="https://medium.com/@eng_elias/building-a-web-search-engine-with-django-a-comprehensive-guide-b2025dd706c"
                  >
                    https://medium.com/@eng_elias/building-a-web-search-engine-with-django-a-comprehensive-guide-b2025dd706c
                  </Link>
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
      </StyledTabPanel>
      <StyledTabPanel value={1}>
        <Grid sx={{ color: "#ffffff", px: 2, pb: 3 }}>
          <Typography variant="h4">Overview:</Typography>
          <Divider />
          <Typography>This is a Django project that includes:</Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>
                a web crawling utility using a Spider class. It allows you to
                crawl web pages, extract information, and store results in the
                database.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>
                The project also provides a Django management command to
                initiate the crawling process.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>
                there is also Django templates UI to allow search in the
                scraping data.
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
      </StyledTabPanel>
    </Tabs>
  );
}

export default SearchEngineSpiderProject;

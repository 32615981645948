import TitleAndImage from "../sections/TitleAndImage";
import WhoAmI from "../sections/WhoAmI";
import MyJourney from "../sections/MyJourney";
import Skills from "../sections/Skills";
import {
  ExperienceScrollPage1,
  ExperienceScrollPage2,
  ExperienceScrollPage3,
  ExperienceScrollPage4,
} from "../sections/Experience";
import FreelanceAccounts from "../sections/FreelanceAccounts";
import DownloadCV from "../sections/DownloadCV";
import ContactMe from "../sections/ContactMe";
import {
  // MoreProjectsAndDetailsOnNotion,
  MyProjects1,
  MyProjects2,
  MyProjects3,
  MyProjects4,
  MyProjects5,
} from "../sections/MyProjects";
import { Grid } from "@mui/material";
import { navItemsIds } from "../components/NavBar";
import { FreestyleAdventures } from "../sections/FreestyleAdventures";
import DigitalStore from "../sections/DigitalStore";

function HomePageMobile() {
  return (
    <Grid container gap={5} direction={"column"} justifyContent={"center"}>
      <Grid>
        <TitleAndImage />
      </Grid>
      <Grid className={navItemsIds.whoAmI} sx={{ py: 10 }}>
        <WhoAmI />
      </Grid>
      <Grid>
        <MyJourney />
      </Grid>
      <Grid className={navItemsIds.skills} sx={{ py: 10 }}>
        <Skills />
      </Grid>
      <Grid sx={{ width: "100%", pt: 10 }} className={navItemsIds.experience}>
        <ExperienceScrollPage1 />
      </Grid>
      <Grid sx={{ width: "100%" }}>
        <ExperienceScrollPage2 />
      </Grid>
      <Grid sx={{ width: "100%" }}>
        <ExperienceScrollPage3 />
      </Grid>
      <Grid sx={{ width: "100%" }}>
        <ExperienceScrollPage4 />
      </Grid>
      <Grid className={navItemsIds.adventures}>
        <FreestyleAdventures />
      </Grid>
      <Grid className={navItemsIds.projects} sx={{ pt: 10 }}>
        <MyProjects1 />
      </Grid>
      <Grid>
        <MyProjects2 />
      </Grid>
      <Grid>
        <MyProjects3 />
      </Grid>
      <Grid>
        <MyProjects4 />
      </Grid>
      <Grid>
        <MyProjects5 />
      </Grid>
      {/* <Grid>
        <MoreProjectsAndDetailsOnNotion />
      </Grid> */}
      <Grid sx={{ py: 10 }}>
        <DigitalStore />
      </Grid>
      <Grid className={navItemsIds.freelancer} sx={{ py: 10 }}>
        <FreelanceAccounts />
      </Grid>
      <Grid className={navItemsIds.cv} sx={{ py: 10 }}>
        <DownloadCV />
      </Grid>
      <Grid
        className={navItemsIds.contactMe}
        sx={{ pt: 10 }}
        display={"flex"}
        direction={"column"}
        justifyContent={"space-between"}
      >
        <ContactMe />
      </Grid>
    </Grid>
  );
}

export default HomePageMobile;

import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Slide, useScrollTrigger } from "@mui/material";
import { blue } from "@mui/material/colors";
import { BASE_URL } from "../utils/urls_utils";

export const navItemsIds = {
  whoAmI: "who-am-i",
  experience: "experience",
  adventures: "adventures",
  projects: "projects",
  freelancer: "freelancer",
  cv: "cv",
  contactMe: "contact-me",
  blog: "blog",
};

export const navItems = [
  { id: navItemsIds.whoAmI, text: "Who Am I?", url: `#${navItemsIds.whoAmI}` },
  {
    id: navItemsIds.experience,
    text: "Experience",
    url: `#${navItemsIds.experience}`,
  },
  {
    id: navItemsIds.adventures,
    text: "Adventures",
    url: `#${navItemsIds.adventures}`,
  },
  {
    id: navItemsIds.projects,
    text: "Projects",
    url: `#${navItemsIds.projects}`,
  },
  {
    id: navItemsIds.freelancer,
    text: "Freelancer",
    url: `#${navItemsIds.freelancer}`,
  },
  { id: navItemsIds.cv, text: "CV", url: `#${navItemsIds.cv}` },
  {
    id: navItemsIds.contactMe,
    text: "Contact me",
    url: `#${navItemsIds.contactMe}`,
  },
];

const styles = {
  navItem: {
    m: 2,
    color: "#ffffff",
    display: "block",
    textAlign: "center",
    minWidth: "fit-content",
  },
};

function NavBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const scrollToElementById = (id) => {
    document.getElementsByClassName(id)[0].scrollIntoView({
      behavior: "smooth",
    });
  };

  const scrollTrigger = useScrollTrigger({
    target: undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!scrollTrigger}>
      <AppBar position="sticky" sx={{ bgcolor: blue[700], zIndex: 998 }}>
        <Container>
          <Toolbar disableGutters>
            <Button
              onClick={scrollToTop}
              sx={{
                display: { xs: "none", lg: "flex" },
              }}
            >
              <Avatar
                variant="rounded"
                sx={{
                  width: 56,
                  height: 56,
                  mr: 1,
                }}
              >
                <img src={`${BASE_URL}/logo.svg`} alt="logo" />
              </Avatar>
            </Button>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", lg: "none" },
                }}
              >
                {navItems.map((item) => (
                  <MenuItem key={item.id} onClick={handleCloseNavMenu}>
                    <Button
                      sx={{ minWidth: "fit-content" }}
                      onClick={() => {
                        setTimeout(() => {
                          scrollToElementById(item.id, { block: "center" });
                          handleCloseNavMenu();
                        }, 100);
                      }}
                    >
                      {item.text}
                    </Button>
                  </MenuItem>
                ))}
                <MenuItem>
                  <Button
                    sx={{ minWidth: "fit-content" }}
                    href={"https://medium.com/@eng_elias"}
                    target="_blank"
                  >
                    Blog
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    sx={{ minWidth: "fit-content" }}
                    href={"https://engelias.gumroad.com/"}
                    target="_blank"
                  >
                    Store
                  </Button>
                </MenuItem>
              </Menu>
            </Box>

            <Button
              onClick={scrollToTop}
              sx={{
                display: { xs: "flex", lg: "none" },
              }}
            >
              <Avatar
                variant="rounded"
                sx={{
                  width: 56,
                  height: 56,
                  m: 1,
                }}
              >
                <img src={`${BASE_URL}/logo.svg`} alt="logo" />
              </Avatar>
            </Button>
            <Box sx={{ flexGrow: 1, display: { xs: "none", lg: "flex" } }}>
              {navItems.map((item) => (
                <Button
                  key={item.id}
                  onClick={() => {
                    scrollToElementById(item.id);
                  }}
                  sx={styles.navItem}
                  color="secondary"
                >
                  {item.text}
                </Button>
              ))}
              <Button
                sx={styles.navItem}
                color="secondary"
                href={"https://medium.com/@eng_elias"}
                target="_blank"
              >
                Blog
              </Button>
              <Button
                sx={styles.navItem}
                color="secondary"
                href={"https://engelias.gumroad.com/"}
                target="_blank"
              >
                Store
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Slide>
  );
}
export default NavBar;

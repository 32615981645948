import BytCryptexProject from "../projects_descriptions/BytCryptexProject";
import GenophoreProject from "../projects_descriptions/GenophoreProject";
import GraduationProject from "../projects_descriptions/GraduationProject";
import OdooProjects from "../projects_descriptions/OdooProjects";
import RealEstateElectronProject from "../projects_descriptions/RealEstateElectronProject";
import SearchEngineSpiderProject from "../projects_descriptions/SearchEngineSpiderProject";
import TopicsDetectorProject from "../projects_descriptions/TopicsDetectorProject";
import VideoRoomsProject from "../projects_descriptions/VideoRoomsProject";
import WordPressProjects from "../projects_descriptions/WordPressProjects";
import { BASE_URL } from "../utils/urls_utils";

export const projects = [
  {
    name: "BytCryptex",
    description:
      "Currency Exchange Telegram Bot with an integrated Admin Panel",
    image: `${BASE_URL}/projects_images/bytcryptex/home_page.jpg`,
    component: <BytCryptexProject />,
  },
  {
    name: "Topics Detector",
    description:
      "A tool which detect the topics of articles using ChatGPT and TextRazor",
    image: `${BASE_URL}/projects_images/topics_detector/topics_detector.jpg`,
    component: <TopicsDetectorProject />,
  },
  {
    name: "Video Rooms",
    description:
      "A video party app where you can upload videos and watch them with your friends at the same time and at the same moment of video.",
    image: `${BASE_URL}/projects_images/video_rooms/0.png`,
    component: <VideoRoomsProject />,
  },
  {
    name: "Search Engine Spider",
    description: "General crawler & Django search engine app over crawled data",
    image: `${BASE_URL}/projects_images/search_engine/search.jpg`,
    component: <SearchEngineSpiderProject />,
  },
  {
    name: "Real Estate Electron",
    description:
      "A real estates management desktop application for a brokerage company",
    image: `${BASE_URL}/projects_images/real_estate/0.png`,
    component: <RealEstateElectronProject />,
  },
  {
    name: "Genophore",
    description: "Sport Social Network With AI Features",
    image: `${BASE_URL}/images/bio.jpg`,
    component: <GenophoreProject />,
  },
  {
    name: "Odoo",
    description:
      "I am a Living Legend on Odoo tutorials and I built many Odoo apps.",
    image: `${BASE_URL}/projects_images/odoo/odoo.svg`,
    component: <OdooProjects />,
  },
  {
    name: "WordPress",
    description:
      "I build many fast, secure, responive and SEO-friendly websites with WordPress",
    image: `${BASE_URL}/projects_images/wordpress/wordpress.svg`,
    component: <WordPressProjects />,
  },
  {
    name: "My Graduation Project",
    description: "Sport Social Network With AI Features",
    image: `${BASE_URL}/projects_images/graduation/api.jpg`,
    component: <GraduationProject />,
  },
];

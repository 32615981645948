import { Grid, Link, Typography } from "@mui/material";
import { ScrollPage } from "react-scroll-motion";
import { BASE_URL } from "../utils/urls_utils";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EngineeringIcon from "@mui/icons-material/Engineering";
import CodeIcon from "@mui/icons-material/Code";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";

function Experience() {
  return (
    <>
      <ScrollPage style={{ height: "initial" }}>
        <Grid sx={{ my: 5 }}>
          <Typography
            h1
            className="neon-text animated-box"
            neontext=""
            sx={{
              textAlign: "center",
              fontSize: "2.5rem",
              width: "75%",
              mx: "auto",
            }}
          >
            Experiences
          </Typography>
        </Grid>
        <Timeline position="right">
          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: "auto 0" }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              <Typography color="gold" fontSize={"2rem"}>
                <b>1/10/2022</b> <ArrowForwardIcon fontSize="small" />{" "}
                <b>Now</b>
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
              <TimelineDot sx={{ backgroundColor: "white" }}>
                <HistoryToggleOffIcon fontSize="large" color="primary" />
              </TimelineDot>
              <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <div className="nft">
                <div className="main">
                  <img
                    className="tokenImage"
                    src={`${BASE_URL}/logo.svg`}
                    alt="Logo"
                  />
                  <h2 style={{ color: "gold" }}>
                    Freelancer Full Stack Developer
                  </h2>
                  <p className="description">
                    Professional Web, Mobile and Desktop Developer.
                    <br />
                    Odoo and Wordpress implementer.
                    <br />
                    Open Source Contributor and Content Writer
                  </p>
                  <hr />
                </div>
              </div>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </ScrollPage>
      <ScrollPage>
        <Timeline position="left">
          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: "auto 0" }}
              variant="body2"
              color="text.secondary"
            >
              <Typography color="gold" fontSize={"2rem"}>
                <b>1/10/2021</b> <ArrowForwardIcon fontSize="medium" />{" "}
                <b>30/9/2022</b>
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
              <TimelineDot sx={{ backgroundColor: "white" }}>
                <LaptopMacIcon fontSize="large" color="primary" />
              </TimelineDot>
              <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <div className="nft">
                <div className="main">
                  <img
                    className="tokenImage"
                    src={`${BASE_URL}/images/dna.png`}
                    alt="Genophore"
                  />
                  <h2 style={{ color: "gold" }}>
                    Genophore Full Stack Web Developer
                  </h2>
                  <p className="description">
                    I worked with{" "}
                    <Link
                      href="https://genophore.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Genophore
                    </Link>{" "}
                    (Bioinformatic Company) as full time employee
                  </p>
                  <hr />
                </div>
              </div>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </ScrollPage>
      <ScrollPage>
        <Timeline position="right">
          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: "auto 0" }}
              variant="body2"
              color="text.secondary"
            >
              <Typography color="gold" fontSize={"2rem"}>
                <b>1/1/2021</b> <ArrowForwardIcon fontSize="medium" />{" "}
                <b>1/2/2022</b>
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
              <TimelineDot sx={{ backgroundColor: "white" }}>
                <EngineeringIcon fontSize="large" color="primary" />
              </TimelineDot>
              <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <div className="nft">
                <div className="main">
                  <img
                    className="tokenImage"
                    src={`${BASE_URL}/images/freelance_work.png`}
                    alt="Freelance"
                  />
                  <h2 style={{ color: "gold" }}>
                    Freelancer Full Stack Web Developer
                  </h2>
                  <p className="description">
                    I started to apply what I studied and practiced on, in real
                    world.
                  </p>
                  <hr />
                </div>
              </div>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </ScrollPage>
      <ScrollPage>
        <Timeline position="left">
          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: "auto 0" }}
              variant="body2"
              color="text.secondary"
            >
              <Typography color="gold" fontSize={"2rem"}>
                <b>9/2016</b> <ArrowForwardIcon fontSize="medium" />{" "}
                <b>9/2021</b>
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
              <TimelineDot sx={{ backgroundColor: "white" }}>
                <CodeIcon fontSize="large" color="primary" />
              </TimelineDot>
              <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <div className="nft">
                <div className="main">
                  <img
                    className="tokenImage"
                    src={`${BASE_URL}/images/student.png`}
                    alt="University"
                  />
                  <h2 style={{ color: "gold" }}>University</h2>
                  <p className="description">
                    I studied Software Engineering at Al-baath University and I
                    practiced a lot to be ready to work as Full Stack Developer.
                  </p>
                  <hr />
                </div>
              </div>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </ScrollPage>
    </>
  );
}

export function ExperienceScrollPage1() {
  return (
    <>
      <Grid sx={{ my: 5 }}>
        <Typography
          h1
          className="neon-text animated-box"
          neontext=""
          sx={{
            textAlign: "center",
            fontSize: "2.2rem",
            width: "75%",
            mx: "auto",
          }}
        >
          Experiences
        </Typography>
      </Grid>
      <Grid>
        <Timeline position="right">
          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: "auto 0" }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              <Typography color="gold" fontSize={"1.5rem"}>
                <b>1/10/2022</b> <ArrowForwardIcon fontSize="small" />{" "}
                <b>Now</b>
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
              <TimelineDot sx={{ backgroundColor: "white" }}>
                <HistoryToggleOffIcon fontSize="large" color="primary" />
              </TimelineDot>
              <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 0 }}>
              <div className="nft">
                <div className="main">
                  <img
                    className="tokenImage"
                    src={`${BASE_URL}/logo.svg`}
                    alt="Logo"
                  />
                  <h3 style={{ color: "gold" }}>
                    Freelancer Full Stack Software Engineer
                  </h3>
                  <p className="description">
                    Software System Analyst.
                    <br />
                    Professional Web, Mobile and Desktop Developer.
                    <br />
                    Open Source Contributor and Content Writer.
                  </p>
                  <hr />
                </div>
              </div>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </Grid>
    </>
  );
}

export function ExperienceScrollPage2() {
  return (
    <Timeline position="left">
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          variant="body2"
          color="text.secondary"
        >
          <Typography color="gold" fontSize={"1.5rem"}>
            <b>1/10/2021</b> <ArrowForwardIcon fontSize="small" />{" "}
            <b>30/9/2022</b>
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
          <TimelineDot sx={{ backgroundColor: "white" }}>
            <LaptopMacIcon fontSize="large" color="primary" />
          </TimelineDot>
          <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 0 }}>
          <div className="nft">
            <div className="main">
              <img
                className="tokenImage"
                src={`${BASE_URL}/images/dna.png`}
                alt="Genophore"
              />
              <h3 style={{ color: "gold" }}>
                Genophore Full Stack Web Developer
              </h3>
              <p className="description">
                I worked with{" "}
                <Link
                  href="https://genophore.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Genophore
                </Link>{" "}
                (Bioinformatic Company) as full time employee
              </p>
              <hr />
            </div>
          </div>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}

export function ExperienceScrollPage3() {
  return (
    <Timeline position="right">
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          variant="body2"
          color="text.secondary"
        >
          <Typography color="gold" fontSize={"1.5rem"}>
            <b>1/1/2021</b> <ArrowForwardIcon fontSize="small" />{" "}
            <b>1/2/2022</b>
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
          <TimelineDot sx={{ backgroundColor: "white" }}>
            <EngineeringIcon fontSize="large" color="primary" />
          </TimelineDot>
          <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 0 }}>
          <div className="nft">
            <div className="main">
              <img
                className="tokenImage"
                src={`${BASE_URL}/images/freelance_work.jpg`}
                alt="Freelance"
              />
              <h3 style={{ color: "gold" }}>
                Freelancer Full Stack Web Developer
              </h3>
              <p className="description">
                I started to apply what I studied and practiced on, in real
                world.
              </p>
              <hr />
            </div>
          </div>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}

export function ExperienceScrollPage4() {
  return (
    <Timeline position="left">
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          variant="body2"
          color="text.secondary"
        >
          <Typography color="gold" fontSize={"1.5rem"}>
            <b>9/2016</b> <ArrowForwardIcon fontSize="small" /> <b>9/2021</b>
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
          <TimelineDot sx={{ backgroundColor: "white" }}>
            <CodeIcon fontSize="large" color="primary" />
          </TimelineDot>
          <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 0 }}>
          <div className="nft">
            <div className="main">
              <img
                className="tokenImage"
                src={`${BASE_URL}/images/student.png`}
                alt="University"
              />
              <h3 style={{ color: "gold" }}>University</h3>
              <p className="description">
                I studied Software Engineering at Al-baath University and I
                practiced a lot to be ready to work as Full Stack Developer.
              </p>
              <hr />
            </div>
          </div>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}

export default Experience;

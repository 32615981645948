import { Grid, Typography } from "@mui/material";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import Diversity2Icon from "@mui/icons-material/Diversity2";

function WhoAmI() {
  return (
    <Grid
      className="animated-box"
      sx={{ mx: "auto", width: "75%", color: "white" }}
    >
      <Grid>
        <Typography
          h1
          className="neon-text"
          neontext=""
          sx={{ textAlign: "center", fontSize: "2.5rem", mb: "1rem" }}
        >
          Who Am I?
        </Typography>
      </Grid>
      <Grid container className="box-text" spacing={5}>
        <Grid item>
          <AlarmOnIcon color="secondary" fontSize="large" /> I am a passionate
          software developer who is constantly seeking out new challenges and
          opportunities to solve everyday problems and create innovative
          solutions that enhance productivity.
        </Grid>
        <Grid item>
          <AutoFixHighIcon color="secondary" fontSize="large" /> With a
          deep-rooted enthusiasm for problem-solving and a keen eye for detail,
          I thrive on converting unconventional ideas into fully-functional
          software applications that push the boundaries of what is possible.
        </Grid>
        <Grid item>
          <Diversity2Icon color="secondary" fontSize="large" /> Additionally, I
          actively contribute to open-source projects, collaborating with a
          global community to drive positive change and make a meaningful impact
          in the world of technology.
        </Grid>
      </Grid>
    </Grid>
  );
}

export default WhoAmI;

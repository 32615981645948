import React from "react";
import { BASE_URL } from "../utils/urls_utils";
import { Button, Grid, Typography } from "@mui/material";

function DownloadCV() {
  return (
    <Grid sx={{ display: "grid", placeItems: "center" }}>
      <Typography
        h1
        className="neon-text animated-box"
        neontext=""
        sx={{
          textAlign: "center",
          fontSize: "2.5rem",
          width: "75%",
          mx: "auto",
        }}
      >
        Download My CV
      </Typography>
      <Button
        sx={{
          m: 1,
          backgroundColor: "#00ff00a0",
          borderRadius: 10,
        }}
        href={`${BASE_URL}/eng_elias_cv.pdf`}
      >
        <img src={`${BASE_URL}/images/resume.png`} alt="My CV" width={300} />
      </Button>
    </Grid>
  );
}

export default DownloadCV;

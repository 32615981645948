import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { projects } from "../data/projects";
import { BASE_URL } from "../utils/urls_utils";
import { useState } from "react";

export function ProjectCard({ project }) {
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const isMobile = useMediaQuery("@media (max-width: 1000px)");

  if (!project) return null;

  return (
    <>
      <Card
        sx={{
          width: isMobile ? 280 : 550,
          mx: "auto",
          background:
            "linear-gradient(0deg, rgb(25, 26, 28) 0%, rgba(17, 0, 32, 0.5) 100%);",
          borderRadius: 10,
          border: "5px solid #1976d2",
          boxShadow: "0 10px 15px 13px #00000088",
        }}
        onClick={handleOpen}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            image={project.image}
            alt="project image"
            sx={{ width: "100%", height: isMobile ? 200 : 350 }}
            style={{ objectFit: "initial" }}
          />
          <CardContent>
            <Grid
              sx={{ display: "flex", mb: 2 }}
              justifyContent={"space-between"}
            >
              <Typography color={"primary"} variant="h5" component="h3">
                {project.name}
              </Typography>
              <Button variant="outlined" size="small">
                See Details
              </Button>
            </Grid>
            <Typography variant="body2" color="white">
              {project.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        sx={{
          width: "80%",
          mx: "auto",
          my: 5,
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {project.component ?? <div />}
      </Modal>
    </>
  );
}

export function MyProjects1() {
  return (
    <>
      <Grid sx={{ my: 5 }}>
        <Typography
          h1
          className="neon-text animated-box"
          neontext=""
          sx={{
            textAlign: "center",
            fontSize: "2.5rem",
            width: "75%",
            mx: "auto",
          }}
        >
          Projects
        </Typography>
      </Grid>
      <Grid container gap={5} justifyContent={"space-around"} sx={{ my: 5 }}>
        <Grid item>
          <ProjectCard project={projects[0]} />
        </Grid>
        <Grid item>
          <ProjectCard project={projects[1]} />
        </Grid>
      </Grid>
    </>
  );
}

export function MyProjects2() {
  return (
    // <ScrollPage>
    <Grid container gap={5} justifyContent={"space-around"} sx={{ my: 5 }}>
      <Grid item>
        {/* <Animator animation={MoveIn(-1000, 0)}> */}
        <ProjectCard project={projects[2]} />
        {/* </Animator> */}
      </Grid>
      <Grid item>
        {/* <Animator animation={MoveIn(1000, 0)}> */}
        <ProjectCard project={projects[3]} />
        {/* </Animator> */}
      </Grid>
    </Grid>
    // </ScrollPage>
  );
}

export function MyProjects3() {
  return (
    // <ScrollPage>
    <Grid container gap={5} justifyContent={"space-around"} sx={{ my: 5 }}>
      <Grid item>
        {/* <Animator animation={MoveIn(-1000, 0)}> */}
        <ProjectCard project={projects[4]} />
        {/* </Animator> */}
      </Grid>
      <Grid item>
        {/* <Animator animation={MoveIn(1000, 0)}> */}
        <ProjectCard project={projects[5]} />
        {/* </Animator> */}
      </Grid>
    </Grid>
    // </ScrollPage>
  );
}

export function MyProjects4() {
  return (
    // <ScrollPage>
    <Grid container gap={5} justifyContent={"space-around"} sx={{ my: 5 }}>
      <Grid item>
        {/* <Animator animation={MoveIn(0, 1000)}> */}
        <ProjectCard project={projects[6]} />
        {/* </Animator> */}
      </Grid>
      <Grid item>
        {/* <Animator animation={MoveIn(1000, 0)}> */}
        <ProjectCard project={projects[7]} />
        {/* </Animator> */}
      </Grid>
    </Grid>
    // </ScrollPage>
  );
}

export function MyProjects5() {
  return (
    // <ScrollPage>
    <Grid container gap={5} justifyContent={"space-around"} sx={{ my: 5 }}>
      <Grid item>
        {/* <Animator animation={MoveIn(0, 1000)}> */}
        <ProjectCard project={projects[8]} />
        {/* </Animator> */}
      </Grid>
      {/* <Grid item> */}
      {/* <Animator animation={MoveIn(1000, 0)}> */}
      {/* <ProjectCard project={projects[7]} /> */}
      {/* </Animator> */}
      {/* </Grid> */}
    </Grid>
    // </ScrollPage>
  );
}

export function MoreProjectsAndDetailsOnNotion() {
  return (
    <Grid
      container
      gap={5}
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ my: 5 }}
    >
      <Typography
        align="center"
        color={"ButtonHighlight"}
        fontSize={"2rem"}
        sx={{ textShadow: "5px 5px 5px #000000" }}
      >
        Visit my Notion public page for more projects and details.
      </Typography>
      <Button
        sx={{
          m: 1,
          backgroundColor: "#ffffff",
          borderRadius: 10,
        }}
        target="_blank"
        href={`https://elias-owis.notion.site/Elias-Owis-Portfolio-9eb85fa9d9544aa6861594310deb63cc?pvs=4`}
      >
        <img
          src={`${BASE_URL}/skills_icons/notion.svg`}
          alt="More projects and details on Notion"
          width={280}
        />
      </Button>
    </Grid>
  );
}

import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Tabs from "@mui/base/Tabs";
import {
  StyledTab,
  StyledTabPanel,
  StyledTabsList,
} from "../../styles/styled_tabs";
import ExtensionIcon from "@mui/icons-material/Extension";

function OdooBusinessIntelligenceProject() {
  const isMobile = useMediaQuery("@media (max-width: 1000px)");

  return (
    <Tabs defaultValue={0}>
      <StyledTabsList>
        <StyledTab value={0}>Gallery</StyledTab>
        <StyledTab value={1}>Features</StyledTab>
        <StyledTab value={2}>Modules</StyledTab>
      </StyledTabsList>
      <StyledTabPanel value={0}>
        <iframe
          height={isMobile ? "300" : "500"}
          style={{ width: "100%" }}
          src="https://www.youtube.com/embed/SYP8-ric1K4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </StyledTabPanel>
      <StyledTabPanel value={1}>
        <Grid sx={{ color: "#ffffff", px: 2 }}>
          <Typography variant="h4">Overview:</Typography>
          <Divider />
          <Typography>
            By using Dashboards module you can know your company status with
            some clicks. you can add Dashboards module to your Odoo ERP and it
            will provides some default statistics dashboards added by installed
            Odoo apps and you can generate your own statistics dashboards from
            any list or graph view with filters and grouping properties you
            want. All dashboards will be interactive and you hide and show some
            data or go to an entity from dashboard.
          </Typography>
        </Grid>
      </StyledTabPanel>
      <StyledTabPanel value={2}>
        <Grid sx={{ color: "#ffffff", px: 2 }}>
          <Typography variant="h4">Odoo Apps & Modules:</Typography>
          <Divider />
          <List>
            <ListItem>
              <ListItemIcon>
                <ExtensionIcon sx={{ color: "#00ff00" }} />
              </ListItemIcon>
              <ListItemText>Dashboards</ListItemText>
            </ListItem>
          </List>
        </Grid>
      </StyledTabPanel>
    </Tabs>
  );
}

export default OdooBusinessIntelligenceProject;

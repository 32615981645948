import { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

function ParcticlesComponents() {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  const mySkills = [
    "Time",
    "Notion",
    "Sport",
    "Books",
    "Agile",
    "Git",
    "JS",
    "TS",
    "Python",
    "Node.js",
    "Django",
    "Angular",
    "React",
    "React Native",
    "Electron.js",
    "Odoo",
    "WordPress",
    "CI/CD",
  ];

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        background: {
          color: {
            value: "#0d47a1",
          },
        },
        fpsLimit: 30,
        interactivity: {
          events: {
            onClick: {
              enable: true,
              mode: ["push", "remove"],
            },
            onHover: {
              enable: true,
              mode: "repulse",
            },
            resize: true,
          },
          modes: {
            push: {
              quantity: 1,
            },
            remove: {
              quantity: 1,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        fullScreen: {
          zIndex: -10,
        },
        particles: {
          color: {
            value: "#ffffff",
          },
          links: {
            color: "#ffffff",
            distance: 200,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            angle: {
              offset: 0,
              value: 90,
            },
            direction: "none",
            enable: true,
            outModes: {
              default: "out",
            },
            random: false,
            speed: 1,
            straight: true,
          },
          number: {
            density: {
              enable: true,
              area: 1000,
            },
            value: 20,
          },
          opacity: {
            value: 0.5,
            animation: {
              count: 0,
              enable: true,
              speed: 0.3,
              decay: 0,
              sync: false,
              destroy: "none",
              startValue: "max",
              minimumValue: 0.3,
            },
          },
          shape: {
            type: "char",
            options: {
              character: {
                value: mySkills,
                font: "Verdana",
                weight: 1000,
                fill: true,
              },
              char: {
                value: mySkills,
                font: "Verdana",
                weight: 1000,
                fill: true,
              },
            },
          },
          size: {
            value: { min: 10, max: 15 },
          },
        },
        detectRetina: true,
        pauseOnBlur: true,
        pauseOnOutsideViewport: true,
      }}
    />
  );
}

export default ParcticlesComponents;
